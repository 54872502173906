
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import { Watch } from "vue-property-decorator";

import SocietyService from "../../services/societymgmt/SocietyService";
import Society from "../../models/societymgmt/Society";

@Component({})
export default class SocietiesComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Society[] = [];
  public fields = [
    {
      key: "shortname",
      sortable: true,
      label: i18n.tc("societymgmt.shortname"),
    },
    {
      key: "name",
      sortable: true,
    },
    {
      key: "oetbWienID",
      sortable: true,
      label: "ÖTB-Wien-ID",
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  mounted(): void {
    this.getAll();
  }

  getAll(): void {
    SocietyService.getAll(true).then((items: Society[]) => {
      this.items = items;
      if (this.items) {
        this.totalRows = this.items.length;
      }
      this.loading = false;
    });
  }

  // CSV Import
  public csvFormData = new FormData();
  // eslint-disable-next-line
  handleFileUpload(event: any) {
    //console.log(event);
    this.csvFormData.append(
      "societyCsv",
      event.target.files[0],
      event.target.files[0].name
    );
  }
  uploadCsv(): void {
    this.loading = true;
    SocietyService.uploadSocietyCsv(this.csvFormData).then(() => {
      // (response) => {
      //console.log(response);
      this.getAll();
      this.loading = false;
    });
  }
}
